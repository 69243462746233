.spinnerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.5s ease-out, visibility 1.5s ease-out;
}

.fadeIn {
  opacity: 1;
  visibility: visible;
}

.fadeOut {
  opacity: 0;
  visibility: hidden;
}
